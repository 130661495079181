import React from "react";
import { ReactNode } from "react";
import CookieConsent from "react-cookie-consent";
import { theme } from "./src/theme";
import { MantineProvider } from "@mantine/core";
import { Layout } from "./src/components/Layout";

export function Providers(props: { children: ReactNode }) {
    const { children } = props
    return <MantineProvider theme={theme}>
        <Layout>
            {children}
        </Layout>
        <CookieConsent>This website uses cookies to enable website functionality and to tailor content to your interests. To find out more about the cookies we use, see our Privacy Policy.</CookieConsent>
    </MantineProvider>
}