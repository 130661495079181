import { ActionIcon, Button, createTheme, FileInput, InputBase, Modal, PasswordInput, SegmentedControl, Tabs, TextInput, Title } from "@mantine/core";

const defaultProps = {
  radius: 'xl',
  size: 'md',
  fz: 'h5'
}

export const theme = createTheme({
  /* Put your mantine theme override here */
  fontFamily: 'Poppins',
  defaultRadius: 'xl',
  primaryColor: 'orange',
  components: {
    Title: Title.extend({
      defaultProps: {
        // ff: "Roboto Slab"
      }
    }),
    InputBase: InputBase.extend({
      defaultProps
    }),
    TextInput: TextInput.extend({
      defaultProps
    }),
    PasswordInput: PasswordInput.extend({
      defaultProps
    }),
    Button: Button.extend({
      defaultProps
    }),
    ActionIcon: ActionIcon.extend({
      defaultProps: {
        ...defaultProps,
        size: 'xl'
      }
    }),
    TimeInput: Button.extend({
      defaultProps
    }),
    // DateTimePicker: DateTimePicker.extend({
    //   defaultProps: {
    //     size: 'md',
    //     radius: 'xl',
    //     fz: 'h5'
    //   }
    // }),
    Modal: Modal.extend({
      defaultProps: {
        radius: 'xl',
        overlayProps: {
          backgroundOpacity: 0.55,
          blur: 3,
        }
      },
    }),
    SegmentedControl: SegmentedControl.extend({
      defaultProps
    }),
    // Notification: Notification.extend({
    //   defaultProps: {
    //     radius: 'xl',
    //     fz: 'md',
    //     styles: {
    //       description: { color: 'white' }
    //     }
    //   }
    // }),
    FileInput: FileInput.extend({
      defaultProps
    }),
    Tabs: Tabs.extend({
      defaultProps: {
        radius: 'lg',
      }
    }),
    TabsTab: Tabs.Tab.extend({
      defaultProps: {
        ff: "Roboto Slab"
      }
    }),
  }
});
