import React from "react";
import { HeaderMegaMenu } from "./Header/HeaderMegaMenu";
import { AppShell, Burger } from "@mantine/core";
import { Footer } from "./Footer/Footer";

export function Layout(props: { children: any }) {
    const { children } = props
    return <>
        <AppShell
            header={{ height: 80 }}
            // padding="md"
            withBorder={false}
        >
            <AppShell.Header bg={'transparent'}>
                <HeaderMegaMenu />
            </AppShell.Header>
            <AppShell.Main>
                {children}
            </AppShell.Main>
            <AppShell.Footer pos={'unset'}>
                <Footer />
            </AppShell.Footer>
        </AppShell>
    </>
}