import { Group, Container, Image, ActionIcon, Text, Box, Divider, Anchor, Stack } from "@mantine/core";
import { IconBrandLinkedin, IconBrandFacebook, IconBrandX, IconBrandYoutube } from "@tabler/icons-react";
import { Link } from "gatsby";
import React from "react";

export function Footer() {
    return <Box bg={'gray.2'} py={'xl'}>
        <Container size={'xl'} h={'100%'}>
            <Stack gap={'xl'}>
                <Group justify="space-between" h={'100%'}>
                    <Link to='/' style={{ textDecoration: 'none' }}>
                        <Group align='center' gap={5}>
                            <Image src={'/img/zt.png'} height={60} />
                            <Text fw={600} fz={'h2'} c={'dark'}>zerotech</Text>
                        </Group>
                    </Link>

                    <Group visibleFrom="sm">
                        <ActionIcon variant='light' color="gray">
                            <IconBrandLinkedin />
                        </ActionIcon>
                        <ActionIcon variant='light' color="gray">
                            <IconBrandFacebook />
                        </ActionIcon>
                        <ActionIcon variant='light' color="gray">
                            <IconBrandX />
                        </ActionIcon>
                        <ActionIcon variant='light' color="gray">
                            <IconBrandYoutube />
                        </ActionIcon>
                    </Group>
                </Group>
                <Divider />
                <Group justify="space-between" h={'100%'}>
                    <Text>
                        © 2024 Zerotech Joinstock Company. All rights reserved.

                    </Text>

                    <Group visibleFrom="sm">
                        <Anchor underline="never" href="/page/privacy-policy">
                            Privacy Policy
                        </Anchor>
                    </Group>
                </Group>
            </Stack>
        </Container>
    </Box>
}