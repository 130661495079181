import "@mantine/core/styles.css";
import React from "react";
import { Providers } from "./providers";
import { ColorSchemeScript } from "@mantine/core";

export const onPreRenderHTML = ({
  getHeadComponents,
  replaceHeadComponents,
}) => {
  const headComponents = getHeadComponents();
  replaceHeadComponents([
    ...headComponents,
    <ColorSchemeScript key="color-scheme-script" />,
    <link rel="preconnect" href="https://fonts.googleapis.com" key={'googleapis'} />,
    <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="anonymous" key={'gstatic'} />,
    <link href="https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap" rel="stylesheet" key={'Poppins'} />
  ]);
};

export const wrapPageElement = ({ element }) => {
  return <Providers>
    {element}
  </Providers>;
};
